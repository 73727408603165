import React, { useEffect } from 'react';
import GlobalHeader from '@/components/GlobalHeader';
import { BrowserRouter as Router, Route, withRouter, Redirect } from 'react-router-dom';
import loadable from '@loadable/component'
import { connect } from 'react-redux'

import { Container, CircularProgress } from '@material-ui/core';

import routes from '@/config/routes';
import WaitForAuth from '@/components/WaitForAuth';
import { trackCurrent } from "@/services/user";

const LoadableComponent = (ComponentPath) => loadable(() => {
  return import(`@/pages/${ComponentPath}`);
}, {
  fallback: (<CircularProgress />)
});

function RouteWithSubRoutes(route) {
  const RouteComponent = LoadableComponent(route.component);

  return (
    <Route
      {...route}
      component={props => {
        // pass the sub-routes down to keep nesting
        const internal =  (
          route.authenticated ? (
            <WaitForAuth><RouteComponent {...props} routes={route.routes} /></WaitForAuth>
          ) : (
            <RouteComponent {...props} routes={route.routes} />
          )
        );

        if (route.redirect) {
          return (
            <Redirect to={route.redirect} />
          );
        }

        if (route.fullWidth) {
          return internal;
        }

        return (
          <Container style={{ padding: 24 }} maxWidth="xl">
            {internal}
          </Container>
        );
      }}
    />
  );

}

function ManageUserData(props){
  const { dispatch, history } = props;

  useEffect(() => {
    if (dispatch) {
      trackCurrent(({ type, data }) => {
        dispatch({
          type: `user/${type}`,
          payload: { data, history }
        });
      });
    }
  }, []);

  return null;
}

const WrappedManageUserData = withRouter(connect(({}) => ({}))(ManageUserData));

function AppRouter() {
  return (
    <Router>
      <WrappedManageUserData />
      <GlobalHeader />
      {
        routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))
      }
    </Router>
  );
}

export default AppRouter;
