import React from 'react';
import AppRouter from '@/layouts/Router';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { ThemeProvider } from '@material-ui/styles';
import 'typeface-lato';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#015d82'
    },
    secondary: {
      main: '#ef802a'
    },
  },
  typography: {
    fontFamily: [
      'Lato'
    ].join(','),
  },
  overrides: {
    MuiRating: {
      iconEmpty: {
        color: '#D8D8D8',
      }
    },
    MuiTab: {
      root: {
        backgroundColor: 'transparent',
        '&$selected': {
          backgroundColor: '#F0EEE5',
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#C8C3AB',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#AFAFAF'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#9D9D9D'
      }
    },
    MuiSlider: {
      track: {
        color: '#1097B4',
      },
      rail: {
        color: '#979797',
        borderRadius: 0
      },
      thumb: {
        color: '#B2E3ED',
        border: 'solid 2px #1097B4'
      },
      valueLabel: {
        backgroundColor: 'green'
      }
    },
    MuiChip: {
      root: {
        borderRadius: 8
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0 12px'
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0
      }
    }
  },
});



const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}



const BasicLayout: React.FC = props => {
  console.log('BasicLayout Props:',window.location.pathname === '/');
  // const atRoot = window.location.pathname === '/';

  // style={ (atRoot && { backgroundImage: 'url("/assets/LandingImage.png")', height: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'  }) }

  return (
    <div>
      <div id="back-to-top-anchor"></div>
      <ThemeProvider theme={theme}>
        <AppRouter />
        <ScrollTop {...props}>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </ThemeProvider>
    </div>
  );
}

export default BasicLayout;
