import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyC0PG8fI301XIqgvCwE1rS48TgBppuCapA",
  authDomain: "zoomality-37e13.firebaseapp.com",
  databaseURL: "https://zoomality-37e13.firebaseio.com",
  projectId: "zoomality-37e13",
  storageBucket: "zoomality-37e13.appspot.com",
  messagingSenderId: "855444152925",
  appId: "1:855444152925:web:baced2ba7db2ba51"
};
firebase.initializeApp(config);

const fbErrorMappings = {
  "auth/wrong-password": "Wrong email/password combination."
};

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const firestore = firebase.firestore;
const FieldValue = firestore.FieldValue;

export { db, auth, firestore, storage, fbErrorMappings, FieldValue };

export default firebase;
