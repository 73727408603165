import { Reducer } from 'redux';
import { Effect } from './index';
import {
  registerUserAsync,
  loginUserAsync,
  logout,
  changePassword,
  updateProfile
} from '@/services/auth';

export interface StateType {
  status?: "ok" | "error";
  message?: string;
  changePasswordStatus?: "ok" | "error";
  changePasswordMessage?: string;
  changePasswordLoading?: boolean;
  loading: boolean;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    register: Effect;
    login: Effect;
    logout: Effect;
    changePassword: Effect;
    updateAvatar: Effect;
    updateProfile: Effect;
    clearStatus: Effect;
  };
  reducers: {
    changeRegisterStatus: Reducer<StateType>;
    changeUpdateProfileStatus: Reducer<StateType>;
    updateState: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'auth',
  state: {
    status: undefined,
    changePasswordLoading: false,
    loading: false,
  },
  effects: {
    *register({ payload }, { call, put }){
      const response = yield call(registerUserAsync, payload);
      yield put({
        type: 'auth/changeRegisterStatus',
        payload: response
      });
    },
    *clearStatus(_, { put }){
      yield put({
        type: 'updateState',
        payload: { status: undefined, message: undefined }
      });
    },
    *login({ payload }, { call, put }){
      yield put({
        type: 'updateState',
        payload: { loading: true },
      });
      console.log('set loading');

      const response = yield call(loginUserAsync, payload);
      console.log('response', response)
      if (response.status === 'ok') {
        window.location = '/match';
      }
      yield put({
        type: 'updateState',
        payload: {
          ...response,
          loading: false,
        }
      });
    },
    *logout({ payload }, { call, apply }) {
      yield call(logout, payload);
      yield apply(payload.history, payload.history.push, ['/user/login']);
    },
    *changePassword({ payload }, { call, put }) {
      yield put({
        type: "changeUpdateProfileStatus",
        payload: {
          changePasswordLoading: true
        }
      });

      //Verify that passwords match
      if (payload.newPassword !== payload.confirmPassword){
        yield put({
          type: "changeUpdateProfileStatus",
          payload: {
            changePasswordStatus: 'error',
            changePasswordMessage: 'Passwords don\'t match.',
            changePasswordLoading: false
          }
        });
        return;
      }

      const response = yield call(changePassword, payload);
      console.log('Response:',response);
      yield put({
        type: "changeUpdateProfileStatus",
        payload: {
          changePasswordStatus: response.status,
          changePasswordMessage: response.message,
          changePasswordLoading: false
        }
      });
    },
    *updateAvatar({ payload }, { call, put }) {
      yield put({
        type: "changeUpdateProfileStatus",
        payload: {
          avatarUploading: true
        }
      });

      const response = yield call(uploadAvatar, payload);
      yield put({
        type: "changeUpdateProfileStatus",
        payload: {
          ...response,
          avatarUploading: false
        }
      });
    },
    *updateProfile({ payload }, { call, put }) {
      console.log("UpdateProfiles:", payload);

      yield put({
        type: "changeUpdateProfileStatus",
        payload: {
          updateProfileLoading: true
        }
      });

      const response = yield call(updateProfile, payload);
      console.log("response:", response);

      yield put({
        type: "changeUpdateProfileStatus",
        payload: {
          updateProfileStatus: response.status,
          updateProfileMessage: response.message,
          updateProfileLoading: false
        }
      });

      return;
    }
  },
  reducers: {
    changeRegisterStatus(state, { payload }){
      return {
        ...state,
        status: payload.status,
        message: payload.message
      };
    },
    changeUpdateProfileStatus(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    updateState(state, { payload }) {
      console.log('Set state:', payload);
      return {
        ...state,
        ...payload
      };
    },
  }
};

export default Model;
