import { auth, db } from '@/utils/firebase';

export async function trackCurrent(CB): void {
  if (!window.profileWatchers) {
    window.profileWatchers = [];
  }
  auth.onAuthStateChanged(user => {
    if (user) {
      const UserRef = db.collection('UserData').doc(user.uid);

      window.profileWatchers.push(
        UserRef.onSnapshot(DocumentSnapshot => {
          const UserData = DocumentSnapshot.data();
          CB({
            type: 'updateUserData',
            data: {
              ...UserData,
              id: DocumentSnapshot.id,
              userRef: UserRef
            }
          });
        })
      );

      CB({ type: 'userChange', data: { auth: user } });
    } else {
      if (window.profileWatchers && window.profileWatchers.length > 0) {
        window.profileWatchers.forEach(profileWatcher => {
          profileWatcher();
        });
        window.profileWatchers = [];
      }
      CB({ type: 'userChange', data: {} });
    }
  });
}

export async function logout(): Promise<void> {
  return auth.signOut();
}
