import { Reducer } from 'redux';
import { Effect } from './index';

//import { registerUserAsync } from '@/services/auth';

export interface UserType {
  DisplayName: string;
}

export interface StateType {
  auth?: any;
  currentUser?: UserType;
  uid?: string | null;
  loading: boolean;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    userChange: Effect;
    updateUserData: Effect;
  };
  reducers: {
    changeUserState: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'user',
  state: { loading: true },
  effects: {
    *userChange({ payload }, { put, apply }) {
      if (payload && payload.data && payload.data.auth){
        yield put({
          type: 'changeUserState',
          payload: {
            auth: payload.data.auth,
            uid: payload.data.auth.uid
          }
        });
        //yield apply(payload.history, payload.history.push, ['/dashboard']);
      } else {
        yield put({
          type: 'changeUserState',
          payload: {
            auth: null,
            uid: null,
            currentUser: null
          }
        });
        if (payload.history) {
          // Redirecting
          if (payload.history.location.pathname.indexOf('/app') > -1){
            yield apply(payload.history, payload.history.push, ['/user/login']);
          }
        }
      }
    },
    *updateUserData({ payload }, { put }) {
      yield put({
        type: 'changeUserState',
        payload: {
          currentUser: payload.data,
          loading: false
        }
      });
    }
  },

  reducers: {
    changeUserState(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
  }
};

export default Model;
