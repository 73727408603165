import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppBar, Button, Toolbar, IconButton, Typography, /*InputBase,*/ Badge, Menu, MenuItem } from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
//import SearchIcon from '@material-ui/icons/Search';
//import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    "position": 'relative',
    "borderRadius": theme.shape.borderRadius,
    "backgroundColor": fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    "marginRight": theme.spacing(2),
    "marginLeft": 0,
    "width": '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

function PrimarySearchAppBar (props) {
  const { user, dispatch, history } = props;
  const { currentUser } = user;
  console.log('user', user)

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen (event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose () {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose () {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleProfileClick () {
    handleMenuClose();
    history.push('/account/profile');
  }

  function handleLogOut () {
    handleMenuClose();
    dispatch({ type: 'auth/logout', payload: { history } });
  }

  function handleMobileMenuOpen (event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
      <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/*<MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>*/}
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow+' no-print'}>
      <div style={{ height: 64 }} />
      <AppBar position="fixed">
        <Toolbar>
          {
            /*
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={ () => alert('Feature not implemented.') }
              >
                <MenuIcon />
              </IconButton>
            */
          }
          <Button onClick={() => history.push('/')} color="inherit">
            <Typography className={classes.title} variant="h6" noWrap>
              Zoomality
            </Typography>
          </Button>

          <div style={{ margin: '0 24px', color: '#fff' }}>
            <Button onClick={() => history.push('/match')} color="inherit">
              Zoom Match
            </Button>
            <Button onClick={() => history.push('/results')} color="inherit">
              Buy
            </Button>
            {
              /*
                <Button onClick={() => history.push('/results')} color="inherit">
                  Top Trending
                </Button>
              */
            }
          </div>

          {/*<div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>*/}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button color="inherit">
              <Typography style={{ textTransform: 'capitalize', color: 'rgba(255, 255, 255, 0.5)' }}>
                {(moment().format("ddd, MMM Do YYYY, hA"))}
              </Typography>
            </Button>
            {/*<IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 0 new notifications" color="inherit" onClick={ () => alert('Feature not implemented.') }>
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>*/}
            {
              currentUser ?
                <Button
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  endIcon={
                    <AccountCircle />
                  }
                >
                  {currentUser.DisplayName}
                </Button> :
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => history.push('/user/login')}
                  style={{ border: '2px solid rgba(239, 128, 42, 0.5)' }}
                >
                  Log In
                </Button>
            }
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default withRouter(connect(({user}) => ({ user }))(PrimarySearchAppBar))
