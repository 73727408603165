import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux'

class WaitForAuth extends Component {
  constructor(props){
    super(props);

    this.renderChildren = this.renderChildren.bind(this);
  }

  renderChildren(children){
    const { user } = this.props;
    if (!user){
      console.error('No user...');
      return null;
    }

    const { uid, loading, currentUser } = user;

    if (uid && currentUser){
      return children;
    }

    if (loading){
      return <CircularProgress />;
    }

    return null;
  }

  render(){
    const { children }  = this.props;
    console.log('Render');
    return (this.renderChildren(children));
  }
}


export default connect(({ user }) => ({ user }))(WaitForAuth);
