export interface RouteInterface {
  path: string;
  name?: string;
  redirect?: string;
  component?: string;
  authenticated?: boolean;
  exact?: boolean;
  menu?: boolean;
  fullWidth?: boolean;
}

const routes: RouteInterface[] = [{
  path: '/choose',
  name: 'Choose',
  component: 'Choose',
}, {
  path: '/results',
  redirect: '/US/results',
  exact: true,
}, {
  path: '/US/:tab',
  name: 'Results',
  component: 'Results',
}, {
  path: '/ListingDemo/',
  name: 'Listing Demo',
  component: 'ListingDemo',
}, {
  path: '/LoginDemo/',
  name: 'Login Demo',
  component: 'LoginDemo',
}, {
  path: '/RegisterDemo/',
  name: 'Register Demo',
  component: 'RegisterDemo',
}, {
  path: '/Profile/',
  name: 'Profile Demo',
  component: 'Profile',
}, {
  path: '/TabsDemo/',
  name: 'Tabs Demo',
  component: 'TabsDemo',
}, {
  path: '/InputsDemo/',
  name: 'Inputs Demo',
  component: 'InputsDemo',
}, {
  path: '/EmptyDemo/',
  name: 'Empty Demo',
  component: 'EmptyDemo',
}, {
  path: '/CompareDemo/',
  name: 'Compare Demo',
  component: 'CompareDemo',
}, {
  path: '/TagsDemo/',
  name: 'Tags Demo',
  component: 'TagsDemo',
}, {
  path: '/',
  name: 'Home',
  component: 'Home',
  fullWidth: true,
  exact: true,
}, {
  path: '/user/register',
  name: 'Register',
  component: 'User/Register/',
}, {
  path: '/user/login',
  name: 'Login',
  component: 'User/Login/',
}, {
  path: '/account/favorites',
  name: 'Favorite Listings',
  component: 'Account/favorites',
  authenticated: true,
}, {
  path: '/account/profile',
  name: 'Account Profile',
  component: 'Account',
  authenticated: true,
}, {
  path: '/match',
  name: 'ZoomMatch',
  component: 'ZoomMatch',
}, {
  path: '/search/:state/:neighborhood',
  name: 'Locality Search',
  component: 'LocalitySearch',
  menu: false,
}, {
  path: '/listing/:id',
  name: 'Listing',
  component: 'Listing',
  menu: false,
}];

export default routes;
