import { combineReducers } from 'redux';
import { all, takeEvery, apply, call, put, SimpleEffect, ForkEffectDescriptor } from 'redux-saga/effects';
import AuthModel from './auth';
import UserModel from './user';

export type AnyAction = any;

type CallFunction = <T>(...args: any[]) => T

export type Effect = (
  action: AnyAction,
  effects: {
    call: <T>(context: unknown, fn: CallFunction) => T,
    put: <T>({ type: string, payload: unknown }) => T
  }
) => void;

type PackagedEffect = () => Generator<SimpleEffect<"FORK", ForkEffectDescriptor<never>>>;

const modelMap = {
  AuthModel,
  UserModel
};


function packageEffect(effect: Effect) {
  return (params: AnyAction) => effect(params, { apply, call, put });
}


export function* rootSaga() {
  let sagaArray: PackagedEffect[] = [];

  Object.values(modelMap).forEach(model =>
    Object.keys(model.effects).forEach(effectKey => {
      sagaArray.push(function*(){
        yield takeEvery(`${model.namespace}/${effectKey}`, packageEffect(model.effects[effectKey]));
      });
    })
  );


  yield all( sagaArray.map(saga => saga()) );
}

let reducerMap = {};
Object.values(modelMap).forEach(model => {
  reducerMap[model.namespace] = function(state: any, action: any){
    if (action.type.indexOf('@@redux') > -1){
      return model.state;
    }

    const reducer = model.reducers[action.type];
    if (typeof(reducer) === 'function'){
      return reducer(state, action);
    }
    return state;
  };
});

export default combineReducers(reducerMap);
