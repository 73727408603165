import { auth, db, storage, fbErrorMappings } from "@/utils/firebase";

export interface RegisterUserParams {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  password: string;
}

export async function registerUserAsync(params: RegisterUserParams) {

  return auth
    .createUserWithEmailAndPassword(params.email, params.password)
    .then(() => {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('Error authenticating.');
      }

      return user.sendEmailVerification();
    })
    .then(() => {
      const user = auth.currentUser;
      if (!user || !user.uid){
        throw new Error('Error authenticating.');
      }

      return db
        .collection('UserData')
        .doc(user.uid)
        .set({
          FirstName: params.firstName,
          LastName: params.lastName,
          DisplayName: params.displayName,
          Email: params.email
        });
    })
    .then(() => ({
      status: 'ok'
    }))
    .catch(error => ({
      status: 'error',
      message: error.message
    }));
}

export interface LoginUserParams {
  email: string;
  password: string;
}

export async function loginUserAsync(params: LoginUserParams) {
  return auth
    .signInWithEmailAndPassword(params.email, params.password)
    .then(() => ({
      status: 'ok'
    }))
    .catch(error => ({
      status: 'error',
      message: error.message
    }));
}

export async function logout(): Promise<void> {
  return auth.signOut();
}

export async function changePassword(params: FormDataType) {
  if (!auth.currentUser || !auth.currentUser.email) {
    return {
      status: "error",
      message: "Not logged in."
    };
  }

  return auth
    .signInWithEmailAndPassword(auth.currentUser.email, params.currentPassword)
    .then(() => auth.currentUser.updatePassword(params.newPassword))
    .then(() => ({
      status: "ok"
    }))
    .catch(error => ({
      status: "error",
      message: fbErrorMappings[error.code] || error.message
    }));
}

export async function uploadAvatar(params: FormDataType) {
  if (!auth.currentUser) {
    return {
      status: "error",
      message: "Not logged in."
    };
  }

  const storageRef = storage.ref();
  const avatarRef = storageRef
    .child("UserData")
    .child(auth.currentUser.uid)
    .child("avatar.jpg");

  return avatarRef
    .put(params.file)
    .then(() => avatarRef.getDownloadURL())
    .then(url =>
      db
        .collection("UserData")
        .doc(auth.currentUser.uid)
        .update({
          Avatar: url
        })
    )
    .then(() => ({
      status: "ok"
    }))
    .catch(error => {
      return {
        status: "error",
        message: fbErrorMappings[error.code] || error.message
      };
    });
}

export async function updateProfile(params: FormDataType) {
  if (!auth.currentUser || !auth.currentUser.email) {
    return {
      status: "error",
      message: "Not logged in."
    };
  }

  return auth
    .signInWithEmailAndPassword(auth.currentUser.email, params.password)
    .then(() => auth.currentUser.updateEmail(params.profile.Email))
    .then(() =>
      db
        .collection("UserData")
        .doc(auth.currentUser.uid)
        .update(params.profile)
    )
    .then(() => ({
      status: "ok"
    }))
    .catch(error => ({
      status: "error",
      message: fbErrorMappings[error.code] || error.message
    }));
}
