import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import BasicLayout from '@/layouts/BasicLayout';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import reducers, { rootSaga } from '@/reducers';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory()

const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);
// Init dispatch
//store.dispatch(getAllProducts());

function App () {
  return (
    <div className="App">
      <Provider store={store}>
        <BasicLayout/>
      </Provider>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
